import { Product } from 'app/collections/product';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SpecificAnalyticsService } from './specific-analytics.service';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';
import { ACCOUNTS } from 'app/resources/account.model';

export type GtmData = { [key: string]: any };
declare var dataLayer;
declare var ADRUM;
import * as sha256 from 'crypto-js/sha256';
import { UITreeNode } from 'primeng/primeng';
import { ConstantsService } from './constants.service';

@Injectable()
export class AnalyticsService {
  registerType: string;
  identificationType: string;
  errorPage;
  hashedDni;
  modDataErrorPageName;
  currentPageName;

  isInvestmentNav = false;
  isCreditLimitNav = false;
  isCampanyaModDatos = false;
  isAltaTipoA = false;

  defaultNavData: Object = {
    'event': 'virtual_page',
    'process': 'Contratación eCommerce',
    'product': undefined,
    'clientId': undefined,
    'idLead': undefined,
    'mail': undefined,
    'hash_dni': undefined,
  }

  defaultFormData: Object = {
    'event': 'form_sent',
    'eventCategory': undefined
  }

  defaultInteractionData: Object = {
    'event': 'CTA',
    'eventCategory': undefined
  }

  filteredSteps: Object = {
    '/bienvenido': 'Paso 01 - Selección identificación',
    '/cuenta-joven/bienvenido': 'Paso 01 - Selección identificación',
    '/confirmacion-otp-primer-paso': 'Paso 01.1 - Validación móvil',
    '/identificacion-edad': 'Paso 01B - Identificación edad',
    '/datos-personal': 'Paso 02 - Datos personales',
    '/datos-contacto': 'Paso 03 - Datos contacto',
    '/datos-personales': 'Paso 02 - Datos personales',
    '/datos-de-contacto': 'Paso 03 - Datos contacto',
    '/datos-laboral': 'Paso 04 - Datos laborales',
    '/metodo-validacion': 'Paso 06 - Método validación',
    '/identificacion-video': 'Paso 07A - Identificación vídeo',
    '/identificacion-iban': 'Paso 07B - Identificación IBAN',
    '/confirmacion-datos': 'Paso 08 - Confirmación datos',
    '/previa-firma': 'Paso 09 - Previa firma',
    '/proceso-firma': 'Paso 10 - Proceso firma',
    '/contratacion-ci': 'Paso 11 - Cuenta firmada',
    '/casi-lo-tenemos': 'Paso 12 - Cuenta firmada-iban',
    '/casi-esta': 'Paso 11 - Cuenta firmada',
    '/404': 'Error - 404',
    '/OCR': 'Error - OCR',
    '/Edad': 'Error - Mayor de 25'
  };

  profileFiltered: Object = {
    '/login': 'Log in',
    '/perfil': 'Perfil',
    '/mis-productos': 'Productos',
    '/detalle-producto': 'Detalle cuenta',
    '/notificacion': 'Notificaciones',
    '/mi-perfil': 'Perfil/Datos',
    '/modificar-datos': 'Perfil/Información Datos',
    '/subir-documentacion': 'Perfil/Subida de DNI',
    '/confirma-otp': 'Perfil/Confirmación OTP',
  }

  evoProtectFiltered: Object = {
    '/evo-protect/requisitos': 'EVO Protect/Requisitos',
    '/evo-protect/tarificador': 'EVO Protect/Simulador Formulario',
    '/evo-protect/simulador-publico': 'EVO Protect/inicio',
    // Contratación microseguros (movido de profileFiltered)
    '/evo-protect/pre-logalty': 'EVO Protect/Pre Logalty Boletín de Adhesión',
    '/evo-protect/configura-microseguro': 'EVO Protect/Paso 01 - Configurador',
    '/evo-protect/configura-beneficiarios': 'EVO Protect/Paso 03 – Selector Beneficiario',
    '/evo-protect/configura-cuenta': 'EVO Protect/Paso 04 – Selector Cuenta',
    '/evo-protect/confirmacion': 'EVO Protect/Paso 05 – OTP',
    '/evo-protect/todo-listo': 'EVO Protect/Paso 06 – OK Alta',
    '/evo-protect/cancelacion-adhesion': 'EVO Protect/Confirmación Cancelación Adhesión',
    '/evo-protect/boletin-adhesion': 'EVO Protect/Boletín Adhesión',
    '/evo-protect/404': 'Error - 404',
    '/404': 'Error - 404'
  }

  seguntoTitularFiltered: Object = {
    '/login-invitacion': 'Cuenta Inteligente/Login Segundo titular',
    '/segundo-titular': 'Cuenta Inteligente/Datos segundo titular',
    '/segundo-titular-fin': 'Cuenta Inteligente/Segundo titular Confirmación',
    '/404': 'Cuenta Inteligente/Error - 404'
  }

  altaClienteFiltered: Object = {
    '/alta-cliente': 'Formulario datos',
    '/confirmacion-otp-primer-paso': 'Formulario OTP',
    '/404': 'Error - 404'
  }

  profileNavData: Object = {
    'event': 'virtual_page',
    'process': 'Área Personal eCommerce',
    'hash_dni': undefined,
    'errorPage': undefined
  }

  profileModDataNavData: Object = {
    'event': 'virtual_page',
    'process': 'Área Personal eCommerce',
    'hash_dni': undefined,
    'errorPage': undefined,
    'pageName': undefined
  }

  profileCreditLimitNavData: Object = {
    'event': 'virtual_page',
    'process': 'Área Personal eCommerce',
    'hash_dni': undefined,
    'errorPage': undefined,
    'pageName': undefined,
    'creditLimit': undefined
  }

  profileInvestmentNavDataFINT: Object = {
    'event': 'virtual_page',
    'process': 'Contratación eCommerce',
    'product': undefined,
    'category': 'inversion',
    'content_type': 'proceso_contratación',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': undefined,
    'origen': undefined,
    'seccion': undefined,
    'holder': undefined,
    'clientType': 'Cliente Tipo B',
    'clientInfo': undefined, // Sólo se informa en consola
    'oferta': undefined
  }

  profileInvestmentNavDataCINV: Object = {
    'event': 'virtual_page',
    'process': 'Contratación eCommerce',
    'product': undefined,
    'category': 'inversion',
    'content_type': 'proceso_contratación',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'flujo': 'Largo',
    'type': undefined,
    'origen': undefined,
    'seccion': undefined,
    'holder': undefined,
    'clientType': 'Cliente Tipo B',
    'clientInfo': undefined, // Sólo se informa en consola
    'oferta': undefined   
  }

  profileBeginCheckout: Object = {
    'event': 'begin_checkout',
    'process': 'Contratación eCommerce',
    'product': undefined,
    'category': 'inversion',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    // 'flujo': undefined,
    'type': undefined,
    'origen': undefined,
    'seccion': undefined,
    'holder': undefined,
    'clientType': 'Cliente Tipo B',
    'clientInfo': undefined, // Sólo se informa en consola
    'oferta': undefined,   
    'ecommerce': {
      'items': [{
        'item_name': undefined,
        'item_id': undefined
      }]
    }
  };

  profilePurchase: Object = {
    'event': 'purchase',
    'process': 'Contratación eCommerce',
    'product': undefined,
    'category': 'inversion',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    // 'flujo': undefined,
    'type': undefined,
    'origen': undefined,
    'seccion': undefined,
    'holder': undefined,
    'clientType': 'Cliente Tipo B',
    'clientInfo': undefined, // Sólo se informa en consola
    'oferta': undefined,
    'ecommerce': {
      'transaction_id': undefined,
      'currency': 'EUR',
      'value': 40,
      'items': [{
        'item_name': undefined,
        'item_id': undefined
      }]
    }
  };

  profileC2C: Object = {
    'event': 'c2c',
    'process': 'Contratación eCommerce',
    'product': undefined,
    'category': 'inversion',
    'content_type': 'formulario_enviado_c2c',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': undefined,
    'origen': undefined,
    'seccion': undefined,
    'holder': undefined,
    'clientType': 'Cliente Tipo B',
    'clientInfo': undefined, // Sólo se informa en consola
    'oferta': undefined,
    'eventCategory': 'Contratación Cuenta Inversora',
    'eventAction': 'C2C_Cuenta Inversora',
    'eventLabel': 'Formulario enviado C2C',        
    'currency': 'EUR',
    'value': 5,
  }

  profileTarificationNavData: Object = {
    'event': 'virtual_page',
    'process': 'Simulador de microseguros',
    'hash_dni': undefined,
    'errorPage': undefined
  }

  profileSegundoTitularNavData: Object = {
    'event': 'virtual_page',
    'process': 'Invitación segundo titular',
    'product': 'Cuenta Inteligente',
    'pageName': undefined
  }

  profileAltaClienteNavData: Object = {
    'event': 'virtual_page',
    'process': 'Captación clientes tipo A',
    'pageName': undefined,
    'hash_dni': undefined,
    'clientId': undefined,
    'errorPage': undefined,
    'origen': undefined,
    'seccion': undefined,
  }

  profileFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Área Personal eCommerce'
  }

  profileTarificationFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Simulador de microseguros'
  }

  segundoTitularFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Invitación segundo titular'
  }

  altaClienteFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Captación clientes tipo A'
  }

  profileNotification: Object = {
    'event': 'notification',
    'eventCategory': 'Área Personal eCommerce'
  }

  profileTarificationNotification: Object = {
    'event': 'notification',
    'eventCategory': 'Simulador microseguros',
  }

  globalTarificationError: Object = {
    'event': 'error',
    'eventCategory': 'Simulador microseguros',
    'eventAction': 'Mensaje de error'
  }

  globalTarification: Object = {
    'event': 'simulador_microseguros',
    'eventCategory': 'Simulador de microseguros',
    'eventAction': 'Calcular precio microseguro'
  };

  unsubscribe: Object = {
    'event': 'CTA',
    'eventCategory': 'Área Personal eCommerce',
    'eventAction': 'Cancelar Boletín de Adhesión'
  }



  dataToHash;

  doNotCheckCookies = false;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private specificAnalytics: SpecificAnalyticsService,
    public authService: AuthService,
    public loginSrv: LoginService  ) {
    this.subscribeToBroadcasts();
    this.addIdLead();
  }

  public subscribeToNav(router) {
    const isNotificationDetail = location.hash.indexOf('/notificacion/') !== -1;
    this.checkIfFunnelCortoCInv();
    router.events.subscribe(event => {
      setTimeout(() => this.getDataLayerParams(event), 2000);
    })
  }

  checkIfFunnelCortoCInv(){
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.codProductoContratado === ConstantsService.AccountTypes.CuentaInversoraCorto.code) {
        this.doNotCheckCookies = true;
      }
    });
  }

  getDataLayerParams(event) {
    let dataLayer = [] = window['dataLayer'];
    if(this.doNotCheckCookies){
      this.triggerNavDataEvent(event);
    } else {
      for (let dL of dataLayer) {
        if (dL.event === 'OneTrustLoaded') {
          if (dL.OnetrustActiveGroups !== ',C0001,') {
            this.triggerNavDataEvent(event);
          }
        }
      }
    }
  }

  triggerNavDataEvent(event){
    const isNotificationDetail = location.hash.indexOf('/notificacion/') !== -1;
    sessionStorage.setItem("isNotificationDetail" , isNotificationDetail.toString());
    const isUrlDefined = typeof event.url === "string";
    sessionStorage.setItem("isUrlDefined" , isUrlDefined.toString());
    const isUrlAferRedirectDefined = typeof event.urlAfterRedirects === "string";
    sessionStorage.setItem("isUrlAferRedirectDefined" , isUrlAferRedirectDefined.toString());
    const eventUrlOrigin = isUrlDefined ? event.url.split('?')[0] : event.url;
    sessionStorage.setItem("eventUrlOrigin" , eventUrlOrigin);
    const eventUrl = isUrlAferRedirectDefined ? event.urlAfterRedirects.split('?')[0] : event.urlAfterRedirects;
    sessionStorage.setItem("eventUrl" , eventUrl);
    this.profileNavData['process'] = 'Área Personal eCommerce';
    let errorEvoProtect = false;
    let errorModData = false;

    this.isAltaTipoA = this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true";

    if (event instanceof NavigationEnd) {

      if (eventUrl === '/login') {
        this.isCreditLimitNav = false;
        this.isInvestmentNav = false;
        this.isCampanyaModDatos = event.urlAfterRedirects.indexOf('subidaDocumentacion') !== -1;
      }

      let myProfileAndModData = this.isCampanyaModDatos && (eventUrl === '/mi-perfil');

      if (this.profileFiltered[eventUrl] !== undefined && !this.isCreditLimitNav && !this.isInvestmentNav && !myProfileAndModData && !this.isAltaTipoA) {
        this.triggerProfileNavData(
          { 'pageName': this.filterProfilePage(eventUrl) }
        );
      }
      else if (isNotificationDetail) {
        const notifTitle = decodeURI(eventUrl.replace('/notificacion/', ''))
        this.triggerProfileNavData(
          { 'pageName': `Notificaciones/ ${notifTitle}` }
        )
      }
      else if (this.evoProtectFiltered[eventUrl] !== undefined && eventUrl !== '/404') {
        this.isCreditLimitNav = false;
        this.isInvestmentNav = false;
        this.profileNavData['process'] = 'Simulador de microseguros';
        this.triggerProfileTarificationNavData(
          { 'pageName': this.filterMicrosegPage(eventUrl) }
        );
      }
      else if (this.seguntoTitularFiltered[eventUrl] !== undefined && eventUrl !== '/404') {
        // this.isCreditLimitNav = false;
        // this.isInvestmentNav = false;
        // this.profileNavData['process'] = 'Simulador de microseguros';
        this.triggerSegundoTitularNavData(
          { 'pageName': this.filterSegundoTitularPage(eventUrl) }
        );
      }
      else if (this.isAltaTipoA && this.altaClienteFiltered[eventUrl] !== undefined && eventUrl !== '/404') {

        this.triggerAltaClienteNavData['clientId'] = this.getClientId();
        this.triggerAltaClienteNavData(
          { 'pageName': this.filterAltaClientePage(eventUrl) }
        );
      }
      else {
        if (eventUrl === '/bienvenido' || eventUrl === '/cuenta-joven/bienvenido') {
          this.dataToHash = undefined;
          this.isCreditLimitNav = false;
          this.isInvestmentNav = false;
        } else {
          if (!this.dataToHash) {
            this.addIdLead();
          }

          //Error 404 de microseguros
          if (eventUrl === '/404' && eventUrlOrigin && eventUrlOrigin.indexOf('evo-protect') !== -1) {
            errorEvoProtect = true;
            this.profileNavData['process'] = 'Simulador de microseguros';
            this.triggerProfileTarificationNavData(
              { 'pageName': this.filterMicrosegPage(eventUrl) }
            );
          }

          //Error 404 de modDatos
          const isModDataErrorPage = this.modDataErrorPageName !== undefined && this.modDataErrorPageName !== '';
          if (isModDataErrorPage) {
            errorModData = true;
            this.addClientId();
            this.triggerProfileModDataNavData(
              { 'pageName': this.modDataErrorPageName }
            );
          }

          //Error 404 de Segundo Titular
          const isSegundoTitularErrorPage = eventUrl === '/404' && this.authService.product === ACCOUNTS.STCode;
          if (isSegundoTitularErrorPage) {
            this.triggerSegundoTitularNavData(
              { 'pageName': this.filterSegundoTitularPage(eventUrl) }
            );
            return;
          }

          //Error 404 de Alta Cliente
          const isAltaClienteErrorPage = eventUrl === '/404' && (this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true");
          if (isAltaClienteErrorPage) {
            this.triggerAltaClienteNavData['clientId'] = this.getClientId();
            this.triggerAltaClienteNavData(
              { 'pageName': this.filterAltaClientePage(eventUrl) }
            );
            return;
          }

          // We keep the Clients ID in case of error (session is erased)
          const isErrorPages = eventUrl === '/404' || eventUrl === '/OCR'
            || eventUrl === '/login-inversora' || eventUrl === '/configuracion-cuenta-inversora'
          if (!isErrorPages) {
            this.addClientId();
          }
        }

        if (!this.isCampanyaModDatos && !this.isCreditLimitNav && !this.isInvestmentNav) {
          this.addProduct();
        } else if (this.isCampanyaModDatos) {
          this.defaultNavData['process'] = 'Área Personal eCommerce';
        }

        if (eventUrl) {
          switch (eventUrl) {
            case '/confirmacion-datos':
            case '/proceso-firma':
            case '/contratacion-ci':
            case '/404':
            case '/OCR':
              if (this.loginSrv.userData) {
                if (errorEvoProtect) {
                  this.defaultNavData['process'] = 'Simulador microseguros';
                } else {
                  this.defaultNavData['process'] = 'Área personal eCommerce';
                }

              }
              break;
          }
          if (this.filterSteps(eventUrl) && !errorEvoProtect && !errorModData && !this.isCreditLimitNav && !this.isInvestmentNav) {
            this.triggerInvestmentDataNavDataNew(this.filterSteps(eventUrl));
          }
        }
      }
      if (window['ADRUM']) {
        const virtualPage = new window['ADRUM'].events.VPageView();
        window['ADRUM'].report(virtualPage);
      }

    }
  }


  public validationAfeterViewInit() {
    const isNotificationDetail = (sessionStorage.getItem("isNotificationDetail") === 'true');
    const isUrlDefined = (sessionStorage.getItem("isUrlDefined") === 'true');
    const isUrlAferRedirectDefined = (sessionStorage.getItem("isUrlAferRedirectDefined") === 'true' );
    const eventUrlOrigin = sessionStorage.getItem("eventUrlOrigin");
    const eventUrl = sessionStorage.getItem("eventUrl");

    this.profileNavData['process'] = 'Área Personal eCommerce';
    let errorEvoProtect = false;
    let errorModData = false;

    this.isAltaTipoA = this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true";


      if (eventUrl === '/login') {
        this.isCreditLimitNav = false;
        this.isInvestmentNav = false;
      }

      let myProfileAndModData = this.isCampanyaModDatos && (eventUrl === '/mi-perfil');

      if (this.profileFiltered[eventUrl] !== undefined && !this.isCreditLimitNav && !this.isInvestmentNav && !myProfileAndModData && !this.isAltaTipoA) {
        this.triggerProfileNavData(
          { 'pageName': this.filterProfilePage(eventUrl) }
        );
      }
      else if (isNotificationDetail) {
        const notifTitle = decodeURI(eventUrl.replace('/notificacion/', ''))
        this.triggerProfileNavData(
          { 'pageName': `Notificaciones/ ${notifTitle}` }
        )
      }
      else if (this.evoProtectFiltered[eventUrl] !== undefined && eventUrl !== '/404') {
        this.isCreditLimitNav = false;
        this.isInvestmentNav = false;
        this.profileNavData['process'] = 'Simulador de microseguros';
        this.triggerProfileTarificationNavData(
          { 'pageName': this.filterMicrosegPage(eventUrl) }
        );
      }
      else if (this.seguntoTitularFiltered[eventUrl] !== undefined && eventUrl !== '/404') {
        // this.isCreditLimitNav = false;
        // this.isInvestmentNav = false;
        // this.profileNavData['process'] = 'Simulador de microseguros';
        this.triggerSegundoTitularNavData(
          { 'pageName': this.filterSegundoTitularPage(eventUrl) }
        );
      }
      else if (this.isAltaTipoA && this.altaClienteFiltered[eventUrl] !== undefined && eventUrl !== '/404') {

        this.triggerAltaClienteNavData['clientId'] = this.getClientId();
        this.triggerAltaClienteNavData(
          { 'pageName': this.filterAltaClientePage(eventUrl) }
        );
      }
      else {
        if (eventUrl === '/bienvenido' || eventUrl === '/cuenta-joven/bienvenido') {
          this.dataToHash = undefined;
          this.isCreditLimitNav = false;
          this.isInvestmentNav = false;
        } else {
          if (!this.dataToHash) {
            this.addIdLead();
          }

          //Error 404 de microseguros
          if (eventUrl === '/404' && eventUrlOrigin && eventUrlOrigin.indexOf('evo-protect') !== -1) {
            errorEvoProtect = true;
            this.profileNavData['process'] = 'Simulador de microseguros';
            this.triggerProfileTarificationNavData(
              { 'pageName': this.filterMicrosegPage(eventUrl) }
            );
          }

          //Error 404 de modDatos
          const isModDataErrorPage = this.modDataErrorPageName !== undefined && this.modDataErrorPageName !== '';
          if (isModDataErrorPage) {
            errorModData = true;
            this.addClientId();
            this.triggerProfileModDataNavData(
              { 'pageName': this.modDataErrorPageName }
            );
          }

          //Error 404 de Segundo Titular
          const isSegundoTitularErrorPage = eventUrl === '/404' && this.authService.product === ACCOUNTS.STCode;
          if (isSegundoTitularErrorPage) {
            this.triggerSegundoTitularNavData(
              { 'pageName': this.filterSegundoTitularPage(eventUrl) }
            );
            return;
          }

          //Error 404 de Alta Cliente
          const isAltaClienteErrorPage = eventUrl === '/404' && (this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true");
          if (isAltaClienteErrorPage) {
            this.triggerAltaClienteNavData['clientId'] = this.getClientId();
            this.triggerAltaClienteNavData(
              { 'pageName': this.filterAltaClientePage(eventUrl) }
            );
            return;
          }

          // We keep the Clients ID in case of error (session is erased)
          const isErrorPages = eventUrl === '/404' || eventUrl === '/OCR'
            || eventUrl === '/login-inversora' || eventUrl === '/configuracion-cuenta-inversora'
          if (!isErrorPages) {
            this.addClientId();
          }
        }

        if (!this.isCampanyaModDatos && !this.isCreditLimitNav && !this.isInvestmentNav) {
          this.addProduct();
        } else if (this.isCampanyaModDatos) {
          this.defaultNavData['process'] = 'Área Personal eCommerce';
        }

        if (eventUrl) {
          switch (eventUrl) {
            case '/confirmacion-datos':
            case '/proceso-firma':
            case '/contratacion-ci':
            case '/404':
            case '/OCR':
              if (this.loginSrv.userData) {
                if (errorEvoProtect) {
                  this.defaultNavData['process'] = 'Simulador microseguros';
                } else {
                  this.defaultNavData['process'] = 'Área personal eCommerce';
                }

              }
              break;
          }
          if (this.filterSteps(eventUrl) && !errorEvoProtect && !errorModData && !this.isCreditLimitNav && !this.isInvestmentNav) {
            this.triggerNavData(
              {
                'step': this.filterSteps(eventUrl),
              }
            )
          }
        }
      }
      if (window['ADRUM']) {
        const virtualPage = new window['ADRUM'].events.VPageView();
        window['ADRUM'].report(virtualPage);
      }

  }
  public subscribeToBroadcasts() {
    this.specificAnalytics.errorPageBroadcast.subscribe((error) => {
      this.errorPage = error;
      this.addErrorPage(error);
    })
  }
  public filterProfilePage(_page) {
    return this.profileFiltered[_page];
  }
  public filterMicrosegPage(_page) {
    return this.evoProtectFiltered[_page];
  }
  public filterSegundoTitularPage(_page) {
    return this.seguntoTitularFiltered[_page];
  }
  public filterAltaClientePage(_page) {
    return this.altaClienteFiltered[_page];
  }
  public filterSteps(step) {
    return this.filteredSteps[step];
  }

  public pushNavData(data: GtmData) {
    window['dataLayer'].push(data);
    console.log(data);
  }

  public triggerProfileNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileNavData,
      ...data
    });
  }

  public triggerProfileTarificationNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileTarificationNavData,
      ...data
    });
  }

  public triggerProfileModDataNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileModDataNavData,
      ...data
    });
    this.modDataErrorPageName='';
  }

  public triggerCreditLimitDataNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileCreditLimitNavData,
      ...data
    });
    this.modDataErrorPageName = '';
    this.isCreditLimitNav = true;
    this.isInvestmentNav = false;
  }

  public triggerInvestmentDataNavData(data?: GtmData) {
    this.profileInvestmentNavDataFINT['origen']= sessionStorage.getItem('origen');
    this.profileInvestmentNavDataFINT['seccion']= sessionStorage.getItem('seccion');
    console.log(this.profileInvestmentNavDataFINT);
    this.pushNavData({
      ...this.profileInvestmentNavDataFINT,
      ...data
    });
    this.modDataErrorPageName = '';
    this.isCreditLimitNav = false;
    this.isInvestmentNav = true;

  }

  public triggerSegundoTitularNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileSegundoTitularNavData,
      ...data
    });
  }

  public triggerAltaClienteNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileAltaClienteNavData,
      ...data
    });
  }

  public triggerNavData(data?: GtmData) {
    this.pushNavData({
      ...this.defaultNavData,
      ...data
    })
  }

  public triggerProfileFormData(form, submitStatus) {

    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.profileFormData,
      ...formattedData
    })
  }

  public triggerProfileModDataFormData(submitStatus, pageName?) {

    if (pageName){
      this.modDataErrorPageName =  pageName;
    }

    const formattedData = {
      'eventAction': `Formulario Modificación de datos DNI`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.profileFormData,
      ...formattedData
    })
  }

  public triggerProfileTarificationFormData(form, submitStatus) {
    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.profileTarificationFormData,
      ...formattedData
    })
  }

  public triggerExtendLimitFormData(submitStatus, requestedLimit) {

    this.isCreditLimitNav = true;
    this.isInvestmentNav = false;

    const formattedData = {
      'eventAction': `Formulario Límite de crédito`,
      'eventLabel': submitStatus,
      'eventValue': requestedLimit
    }
    this.pushFormData({
      ...this.profileFormData,
      ...formattedData
    })
  }

  public triggerInvestmentFormData(submitStatus) {

    this.isCreditLimitNav = false;
    this.isInvestmentNav = true;

    const formattedData = {
      'eventAction': `Formulario Modificación de datos`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.profileFormData,
      ...formattedData
    })
  }

  public triggerSegundoTitularFormData(form, submitStatus) {
    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.segundoTitularFormData,
      ...formattedData
    })
  }

  public triggerAltaClienteFormData(form, submitStatus) {
    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.altaClienteFormData,
      ...formattedData
    })
  }

  public triggerFormData(form, submitStatus) {
    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    if ( form === 'Datos Confirmación otp') {
      this.defaultFormData['eventCategory'] = 'Área Personal eCommerce';
    }

    this.pushFormData({
      ...this.defaultFormData,
      ...formattedData
    })
  }

  public pushFormData(data: GtmData) {
    window['dataLayer'].push(data);
  }

  public triggerNotificationAction(eventAction, eventLabel?) {
    const filteredData = {
      'eventAction': eventAction,
      'eventLabel': eventLabel
    }
    this.pushFormData({
      ...this.profileNotification,
      ...filteredData
    })
  }

  public triggerTarificationNotificationAction(eventAction, eventLabel?) {
    const filteredData = {
      'eventAction': eventAction,
      'eventLabel': eventLabel
    }
    this.pushFormData({
      ...this.profileTarificationNotification,
      ...filteredData
    })
  }

  public triggerErrorAction(eventProfile, eventLabel?) {
    const filteredData = {
      'eventLabel': eventLabel
    }
    this.pushFormData({
      ...eventProfile,
      ...filteredData
    })
  }

  public triggerInteractionDataNew(codProducto, eventAction, eventLabel?, clientId?){
    switch (codProducto) {
      case ConstantsService.AccountTypes.CuentaInversora.code:
      case ConstantsService.AccountTypes.CuentaInversoraCorto.code:
        this.defaultInteractionData['eventCategory'] = 'Contratación Cuenta Inversora';
        break;
      case ConstantsService.AccountTypes.AmpliacionLimite.code:
      case ConstantsService.AccountTypes.FondoInteligente.code:
      default:
        this.defaultInteractionData['eventCategory'] = this.defaultInteractionData['eventCategory'] || undefined;
        break;
    }
    this.triggerInteractionData(eventAction, eventLabel, clientId);
  }

  public triggerInteractionData(eventAction, eventLabel?, clientId?) {
    const filteredData = {
      'eventAction': eventAction,
      'eventLabel': eventLabel,
      'clientId': clientId
    }
    this.pushInteractionData({
      ...this.defaultInteractionData,
      ...filteredData
    })
  }

  public pushInteractionData(data: GtmData) {
    const element = Object.assign({}, data);
    window['dataLayer'].push(element);
    console.log(element);
  }

  private _hashId(email) {
    return sha256(email);
  }

  public createHashId() {
    const session = this.authService.getSessionProspect();
    if (session.datosPersonales && !this.dataToHash) {
      this.dataToHash = session.datosPersonales.telefonoMovil ? session.datosPersonales.telefonoMovil : session.datosPersonales.email
    }

    if (this.dataToHash) {
      return this._hashId(this.dataToHash).toString();
    }
  }
  public createHasProperty(tipo, value){
    switch (tipo) {
      case 'dni':
        this.defaultNavData['hash_dni'] = sha256(value).toString();
      break;
      case 'mail':
        this.defaultNavData['mail'] = sha256(value).toString();
      break;
    }

  }
  public getClientId() {
    if (this.loginSrv.userData && this.loginSrv.userData.persona) {
      return JSON.parse(sessionStorage.getItem('userData')).persona.id_cliente_EVO;
    } else if(this.loginSrv.userData && this.loginSrv.userData.userinfo){
      return JSON.parse(sessionStorage.getItem('userData')).userinfo.usuario;
    } else {
      const session = this.authService.getSessionProspect();
      if (session.datosPersonales) {
        return session.datosPersonales.id_cliente_EVO;
      }
    }
  }

  public addProduct() {
    let isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    let isCuentaInteligente =  !this.authService.product || this.authService.product === ACCOUNTS.CICode;
    let isCuentaInversora = this.authService.product === ACCOUNTS.CINVCode;
    let isCuentaInversoraCorto = this.authService.product === ACCOUNTS.CINVCortoCode;
    let isFondoInteligente = this.authService.product === ACCOUNTS.FINTCode;
    let isSegundoTitular = this.authService.product === ACCOUNTS.STCode;
    let product = 'Área Personal eCommerce';
    if (isYoungAccount){
      product = 'Cuenta Joven';
    } else if (isCuentaInteligente || isSegundoTitular){
      product = 'Cuenta Inteligente';
    } else if (isCuentaInversora){
      product = 'Cuenta Inversora';
    } else if (isFondoInteligente){
      product = 'Fondo Inteligente';
    } else if (isCuentaInversoraCorto){
      product = 'Cuenta Inversora';
    }
    return this.setProduct(product);
  }

  public setProduct(product) {
    if (product === 'Área Personal eCommerce'){
      this.defaultFormData['eventCategory'] = 'Área Personal eCommerce';
    }
    this.defaultNavData['product'] = product;
    if (location.hash.indexOf('evo-protect') !== -1) {
      if(location.hash.indexOf('tarificador') !== -1) {
        this.defaultInteractionData['eventCategory'] = 'Simulador de microseguros';
        this.defaultFormData['eventCategory'] = 'Simulador de microseguros';
      }
      else{
        this.defaultInteractionData['eventCategory'] = 'Simulador de microseguros';
        this.defaultFormData['eventCategory'] = 'Simulador de microseguros';
      }

      return;
    }
    if (this.loginSrv.userData) {
      const acronym = product === 'Cuenta Inteligente' ? 'CI' : 'CJ';
      this.defaultInteractionData['eventCategory'] = `Área Personal eCommerce - Contratación ${acronym}`;
      this.defaultFormData['eventCategory'] = `Área Personal eCommerce - Contratación ${acronym}`;
    } else {
      this.defaultInteractionData['eventCategory'] = `Contratación ${product}`;
      this.defaultFormData['eventCategory'] = `Contratación ${product}`;
    }
  }

  public addErrorPage(error) {
    //this.profileCreditLimitNavData['errorPage'] = error;
    this.profileNavData['errorPage'] = error;
    return this.defaultNavData['errorPage'] = error;
  }

  public addHolder(holder) {
    return this.profileInvestmentNavDataFINT['holder'] = holder;
  }

  public addIdLead() {
    return this.defaultNavData['idLead'] = this.createHashId();
  }

  public addClientId() {
    return this.defaultNavData['clientId'] = this.getClientId();
  }

  public addTarificationEvent(step, value?) {
    if (this.errorSteps(step)) return;
    if (value) {
      value = value.replace(',', '.');
    }
    switch (step) {
      case 1:
        this.globalTarification['eventLabel'] = "Paso 01 - Acceso a formulario";
        delete this.globalTarification['eventValue'];
        break;
      case 2:
        this.globalTarification['eventLabel'] = "Paso 02 - Precio calculado";
        this.globalTarification['eventValue'] = value;
        break;
      case 3:
        this.globalTarification['eventLabel'] = "Paso 03 - Me interesa contratar";
        this.globalTarification['eventValue'] = value;
        break;
      case 4:
        this.globalTarification['eventAction'] = 'Log in';
        this.globalTarification['eventLabel'] = "Pop up microseguros";
        delete this.globalTarification['eventValue'];
        break;
      case 5:
        this.globalTarification['eventAction'] = 'Acceso a  v2.cuentainteligente.evobanco.com';
        this.globalTarification['eventLabel'] = "Pop up microseguros";
        delete this.globalTarification['eventValue'];
        break;
    }

    this.pushInteractionData(this.globalTarification);
  }

  public cancelAdhesionbulletin(step) {
    if (this.errorSteps(step)) return;
    switch (step) {
      case 1:
        this.unsubscribe['eventLabel'] = "Paso 01 - Cancelar Boletín de Adhesión"
        break;
      case 2:
        this.unsubscribe['eventLabel'] = "Paso 02 - Confirmar Cancelación"
        break;
    }
    this.pushInteractionData(this.unsubscribe);
  }

  public addPageLikeEvent(step) {
    if (this.errorSteps(step)) return;
    let pageValue = ''
    switch (step) {
      case 1:
        pageValue = "EVO Protect/Post Logalty Boletín de Adhesión";
        break;
      case 2:
        pageValue = "EVO Protect/Paso 02 – Capital cuota";
        break;
      case 3:
        pageValue = "EVO Protect/Simulador Capital cuota";
        break;
    };
    this.triggerProfileTarificationNavData(
      { 'pageName': pageValue }
    );
  }

  public errorSteps(step) {
    if (!step) {
      console.error('No se ha indicado un paso en el flujo de analítica');
      return true
    };
    return false
  }

  public stopProfileNavTags(){
    this.isCampanyaModDatos = true;
  }

  public triggerInvestmentDataNavDataNew(pageName: string, idCliente: string = '') {


    const productoContratando = sessionStorage.getItem("productoContratando");
    const isTwoHolders = sessionStorage.getItem('isTwoHolders') === 'true'
      || sessionStorage.getItem('segundoTit') === 'true'
      || sessionStorage.getItem('activarSegundoTitular') === 'true'
      || sessionStorage.getItem('isSecondSigner') === 'true';
    const isSecondHolder = sessionStorage.getItem('isSecondSigner') === 'true'   

    switch (productoContratando) {

      case ConstantsService.AccountTypes.CuentaInversora.code:
        this.setCuentaInversoraFields(pageName, ConstantsService.AccountTypes.CuentaInversora);
        break;

      case ConstantsService.AccountTypes.CuentaInversoraCorto.code:
        this.setCuentaInversoraFields(pageName, ConstantsService.AccountTypes.CuentaInversoraCorto, idCliente);
        break;

      case ConstantsService.AccountTypes.FondoInteligente.code:
        const caso = sessionStorage.getItem('numCaso') || sessionStorage.getItem('casoSeleccionado');
        this.profileInvestmentNavDataFINT['origen']= sessionStorage.getItem('origen') || 'BE';
        this.profileInvestmentNavDataFINT['seccion']= sessionStorage.getItem('seccion') || 'BE';
        this.profileInvestmentNavDataFINT['oferta']= sessionStorage.getItem('oferta') || undefined;
        this.profileInvestmentNavDataFINT['type']= 'generico';
        this.profileInvestmentNavDataFINT['clientId']= (idCliente == '') ? this.getClientId() : idCliente;
        this.profileInvestmentNavDataFINT['step'] = pageName;
        this.profileInvestmentNavDataFINT['product'] = ConstantsService.AccountTypes.FondoInteligente.product;
        this.profileInvestmentNavDataFINT['product_id'] = `${ConstantsService.AccountTypes.FondoInteligente.product} - ${caso==="undefined"?sessionStorage.getItem('casoSeleccionado'):caso}`;
        this.profileInvestmentNavDataFINT['holder'] = isTwoHolders ? 'titular 2' : 'titular único';

        //Objeto creado para enviar datos no undefined, null, o ' ' // MEIF0-7227
        try {

          const  profileInvestmentNavDataFINT = {
            'event': 'virtual_page',
            'process': 'Contratación eCommerce',
            'product': this.profileInvestmentNavDataFINT['product'],
            'category': 'inversion',
            'content_type': 'proceso_contratación',
            // 'clientId': this.profileInvestmentNavDataFINT['clientId'],
            'step': this.profileInvestmentNavDataFINT['step'],
            'type': this.profileInvestmentNavDataFINT['type'],
            'holder': this.profileInvestmentNavDataFINT['holder'],
            'clientType': 'Cliente Tipo B',
          }
          if(this.profileInvestmentNavDataFINT['oferta']){profileInvestmentNavDataFINT['oferta']= this.profileInvestmentNavDataFINT['oferta']}
          if(this.profileInvestmentNavDataFINT['seccion']){profileInvestmentNavDataFINT['seccion']= this.profileInvestmentNavDataFINT['seccion']}
          if(this.profileInvestmentNavDataFINT['origen']){profileInvestmentNavDataFINT['origen']= this.profileInvestmentNavDataFINT['origen']}
          if(this.profileInvestmentNavDataFINT['product_id']){profileInvestmentNavDataFINT['product_id']= this.profileInvestmentNavDataFINT['product_id']}
          if (this.profileInvestmentNavDataFINT[ 'clientId' ]) { profileInvestmentNavDataFINT[ 'clientId' ] = this.profileInvestmentNavDataFINT[ 'clientId' ] }

          this.pushNavData({ ...profileInvestmentNavDataFINT });

        } catch (error) {

          this.pushNavData(this.profileInvestmentNavDataFINT)
        }

        if(pageName == 'Configuración nickname'){
          this.triggerBeginCheckoutFINT();
        }

        if(pageName == 'Fondo Inteligente firmado'){
          this.triggerPurchaseFINT();
        }
        break;

      default:
        break;
    }

    this.modDataErrorPageName = '';
    this.isCreditLimitNav = false;
    this.isInvestmentNav = true;

  }

  setCuentaInversoraFields(pageName, account = {product: '', flujo: ''},idCliente: string = ''){

    const caso = sessionStorage.getItem('numCaso') || sessionStorage.getItem('casoSeleccionado');
    const isTwoHolders = sessionStorage.getItem('isTwoHolders') === 'true'
      || sessionStorage.getItem('segundoTit') === 'true'
      || sessionStorage.getItem('activarSegundoTitular') === 'true'
      || sessionStorage.getItem('isSecondSigner');
    const isSecondHolder = sessionStorage.getItem('isSecondSigner') === 'true'

    this.profileInvestmentNavDataCINV['origen']= sessionStorage.getItem('origen') || 'BE';
    this.profileInvestmentNavDataCINV['seccion']= sessionStorage.getItem('seccion') || 'BE';
    this.profileInvestmentNavDataCINV['oferta']= sessionStorage.getItem('oferta') || undefined;
    this.profileInvestmentNavDataCINV['type']= sessionStorage.getItem('seccion') || 'BE';;
    this.profileInvestmentNavDataCINV['clientId']= (idCliente == '') ? this.getClientId() : idCliente;
    this.profileInvestmentNavDataCINV['step']= pageName;
    this.profileInvestmentNavDataCINV['product'] = account.product;
    this.profileInvestmentNavDataCINV['product_id'] = `${account.product} - ${caso==="undefined"?sessionStorage.getItem('casoSeleccionado'):caso}`;
    this.profileInvestmentNavDataCINV['holder'] = isTwoHolders ? 'titular 2' : 'titular único';
    this.profileInvestmentNavDataCINV['flujo'] = 'Largo';

    //Objeto creado para enviar dataLayer con valores no undefined, null o ''  //MEIF0-7226
    try {

      const profileInvestmentNavDataCINV  = {
        'event': 'virtual_page',
        'process': 'Contratación eCommerce',
        'product': this.profileInvestmentNavDataCINV['product'],
        'category': 'inversion',
        'content_type': 'proceso_contratación',
        // 'clientId': this.profileInvestmentNavDataCINV['client_id'],
        'step': this.profileInvestmentNavDataCINV['step'],
        'flujo': 'Largo',
        'type': this.profileInvestmentNavDataCINV['type'],
        'holder': this.profileInvestmentNavDataCINV['holder'],
        'clientType': 'Cliente Tipo B',

      }
      if(this.profileInvestmentNavDataCINV['oferta']){profileInvestmentNavDataCINV['oferta'] = this.profileInvestmentNavDataCINV['oferta']}
      if(this.profileInvestmentNavDataCINV['seccion']){profileInvestmentNavDataCINV['seccion'] = this.profileInvestmentNavDataCINV['seccion']}
      if(this.profileInvestmentNavDataCINV['origen']){profileInvestmentNavDataCINV['origen'] = this.profileInvestmentNavDataCINV['origen']}
      if(this.profileInvestmentNavDataCINV['product_id']){profileInvestmentNavDataCINV['product_id'] = this.profileInvestmentNavDataCINV['product_id']}
      if (this.profileInvestmentNavDataCINV['clientId']) { profileInvestmentNavDataCINV['clientId'] = this.profileInvestmentNavDataCINV['clientId']}

      this.pushNavData({...profileInvestmentNavDataCINV});
    } catch (error) {

      this.pushNavData(this.profileInvestmentNavDataCINV)
    }

    if(pageName == 'Configuración nickname'){
      this.triggerBeginCheckoutCINV();
    }

    if(pageName == 'Cuenta firmada'){
      this.triggerPurchaseCINV();
    }

  }

  public triggerBeginCheckoutCINV() {
    this.profileBeginCheckout['origen'] = this.profileInvestmentNavDataCINV['origen'];
    this.profileBeginCheckout['seccion'] = this.profileInvestmentNavDataCINV['seccion'];
    this.profileBeginCheckout['oferta'] = this.profileInvestmentNavDataCINV['oferta'];
    this.profileBeginCheckout['type'] = this.profileInvestmentNavDataCINV['type'];
    this.profileBeginCheckout['clientId'] = this.profileInvestmentNavDataCINV['clientId'];
    this.profileBeginCheckout['step'] = this.profileInvestmentNavDataCINV['step'];
    this.profileBeginCheckout['product'] = this.profileInvestmentNavDataCINV['product'];
    this.profileBeginCheckout['product_id'] = this.profileInvestmentNavDataCINV['product_id'];
    this.profileBeginCheckout['holder'] = this.profileInvestmentNavDataCINV['holder'];
    this.profileBeginCheckout['flujo'] = this.profileInvestmentNavDataCINV['flujo'];
    this.profileBeginCheckout['ecommerce']['items'][0]['item_name'] = this.profileInvestmentNavDataCINV['product'];
    let itemIdType = (this.profilePurchase['type']) ? this.profilePurchase['type'] : 'generico';
    this.profileBeginCheckout['ecommerce']['items'][0]['item_id'] = this.profileInvestmentNavDataCINV['product'] + '_' + itemIdType;
    //Objeto creado para evitar los campos undefined, null o '' en el dataLayer //MEIF0-7226
    try {

      const profileBeginCheckout = {
        'event': 'begin_checkout',
        'process': 'Contratación eCommerce',
        'category': 'inversion',
        'content_type': 'proceso_contratacion',
        'clientType': 'Cliente Tipo B',

      }
      if (this.profileBeginCheckout[ 'product' ]) { profileBeginCheckout[ 'product' ] = this.profileBeginCheckout[ 'product' ] }
      if (this.profileBeginCheckout[ 'clientId' ]) { profileBeginCheckout[ 'product' ] = this.profileBeginCheckout[ 'client_id' ] }
      if (this.profileBeginCheckout[ 'step' ]) { profileBeginCheckout[ 'step' ] = this.profileBeginCheckout[ 'client_id' ] }
      if (this.profileBeginCheckout[ 'product_id' ]) { profileBeginCheckout[ 'product_id' ] = this.profileBeginCheckout[ 'product_id' ] }
      if (this.profileBeginCheckout[ 'flujo' ]) { profileBeginCheckout[ 'flujo' ] = this.profileBeginCheckout[ 'flujo' ] }
      if (this.profileBeginCheckout[ 'type' ]) { profileBeginCheckout[ 'type' ] = this.profileBeginCheckout[ 'type' ] }
      if (this.profileBeginCheckout[ 'origen' ]) { profileBeginCheckout[ 'origen' ] = this.profileBeginCheckout[ 'origen' ] }
      if (this.profileBeginCheckout[ 'seccion' ]) { profileBeginCheckout[ 'seccion' ] = this.profileBeginCheckout[ 'seccion' ] }
      if (this.profileBeginCheckout[ 'holder' ]) { profileBeginCheckout[ 'holder' ] = this.profileBeginCheckout[ 'holder' ] }
      if (this.profileBeginCheckout[ 'clientInfo' ]) { profileBeginCheckout[ 'clientInfo' ] = this.profileBeginCheckout[ 'clientInfo' ] }
      if (this.profileBeginCheckout[ 'oferta' ]) { profileBeginCheckout[ 'oferta' ] = this.profileBeginCheckout[ 'oferta' ] }
      if (this.profileBeginCheckout[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ]) {
        profileBeginCheckout[ 'ecommerce' ] = {
          'items': [
            {
              'item_name': this.profileBeginCheckout[ 'product' ],
              'item_id': this.profileBeginCheckout[ 'product' ] + '_' + itemIdType
            }
          ]
        }
      };
      if (this.profileBeginCheckout[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]) {
        profileBeginCheckout[ 'ecommerce' ] = {
          'items': [
            {
              'item_id': this.profileBeginCheckout[ 'product' ] + '_' + itemIdType
            }
          ]
        }
      };

      this.pushNavData({...profileBeginCheckout});
    } catch (error) {
      this.pushNavData(this.profileBeginCheckout);
    }
  }

  public triggerBeginCheckoutFINT() {
    this.profileBeginCheckout['origen'] = this.profileInvestmentNavDataFINT['origen'];
    this.profileBeginCheckout['seccion'] = this.profileInvestmentNavDataFINT['seccion'];
    this.profileBeginCheckout['oferta'] = this.profileInvestmentNavDataFINT['oferta'];
    this.profileBeginCheckout['type'] = this.profileInvestmentNavDataFINT['type'];
    this.profileBeginCheckout['clientId'] = this.profileInvestmentNavDataFINT['clientId'];
    this.profileBeginCheckout['step'] = this.profileInvestmentNavDataFINT['step'];
    this.profileBeginCheckout['product'] = this.profileInvestmentNavDataFINT['product'];
    this.profileBeginCheckout['product_id'] = this.profileInvestmentNavDataFINT['product_id'];
    this.profileBeginCheckout['holder'] = this.profileInvestmentNavDataFINT['holder'];
    this.profileBeginCheckout['ecommerce']['items'][0]['item_name'] = this.profileInvestmentNavDataFINT['product'];
    let itemIdType = (this.profilePurchase['type']) ? this.profilePurchase['type'] : 'generico';
    this.profileBeginCheckout['ecommerce']['items'][0]['item_id'] = this.profileInvestmentNavDataFINT['product'] + '_' + itemIdType;

    //Objeto creado para enviar datos undefined, null, o ' ' // MEIF0-7227
    try {

      const profileBeginCheckout = {
        'event': 'begin_checkout',
        'process': 'Contratación eCommerce',
        'category': 'inversion',
        'content_type': 'proceso_contratacion',
        'clientType': 'Cliente Tipo B',
      };
      if (this.profileBeginCheckout[ 'product' ]) { profileBeginCheckout[ 'product' ] = this.profileBeginCheckout[ 'product' ] }
      if (this.profileBeginCheckout[ 'clientId' ]) { profileBeginCheckout[ 'clientId' ] = this.profileBeginCheckout[ 'clientId' ] }
      if (this.profileBeginCheckout[ 'step' ]) { profileBeginCheckout[ 'step' ] = this.profileBeginCheckout[ 'step' ] }
      if (this.profileBeginCheckout[ 'product_id' ]) { profileBeginCheckout[ 'product_id' ] = this.profileBeginCheckout[ 'product_id' ] }
      if (this.profileBeginCheckout[ 'flujo' ]) { profileBeginCheckout[ 'flujo' ] = this.profileBeginCheckout[ 'flujo' ] }
      if (this.profileBeginCheckout[ 'type' ]) { profileBeginCheckout[ 'type' ] = this.profileBeginCheckout[ 'type' ] }
      if (this.profileBeginCheckout[ 'origen' ]) { profileBeginCheckout[ 'origen' ] = this.profileBeginCheckout[ 'origen' ] }
      if (this.profileBeginCheckout[ 'seccion' ]) { profileBeginCheckout[ 'seccion' ] = this.profileBeginCheckout[ 'seccion' ] }
      if (this.profileBeginCheckout[ 'holder' ]) { profileBeginCheckout[ 'holder' ] = this.profileBeginCheckout[ 'holder' ] }
      if (this.profileBeginCheckout[ 'clientInfo' ]) { profileBeginCheckout[ 'clientInfo' ] = this.profileBeginCheckout[ 'clientInfo' ] }
      if (this.profileBeginCheckout[ 'oferta' ]) { profileBeginCheckout[ 'oferta' ] = this.profileBeginCheckout[ 'oferta' ] }
      if (this.profileBeginCheckout[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ]) {
        profileBeginCheckout[ 'ecommerce' ] = {
          'items': [
            {
              'item_name': this.profileBeginCheckout[ 'product' ],
              'item_id': this.profileBeginCheckout[ 'product' ] + '_' + itemIdType
            }
          ]
        }
      }
      if (this.profileBeginCheckout[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]) {
        profileBeginCheckout[ 'ecommerce' ] = {
          'items': [
            {
              'item_id': this.profileBeginCheckout[ 'product' ] + '_' + itemIdType
            }
          ]

      }[ 'items' ][ 0 ][ 'item_id' ] = this.profileBeginCheckout[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ] }

      this.pushNavData({ ...profileBeginCheckout });
    } catch (error) {
      this.pushNavData(this.profileBeginCheckout);
    }
  }

  public triggerPurchaseCINV() {
    const caso = sessionStorage.getItem('numCaso') || sessionStorage.getItem('casoSeleccionado');
    this.profilePurchase['origen'] = this.profileInvestmentNavDataCINV['origen'];
    this.profilePurchase['seccion'] = this.profileInvestmentNavDataCINV['seccion'];
    this.profilePurchase['oferta'] = this.profileInvestmentNavDataCINV['oferta'];
    this.profilePurchase['type'] = this.profileInvestmentNavDataCINV['type'];
    this.profilePurchase['clientId'] = this.profileInvestmentNavDataCINV['clientId'];
    this.profilePurchase['step'] = this.profileInvestmentNavDataCINV['step'];
    this.profilePurchase['product'] = this.profileInvestmentNavDataCINV['product'];
    this.profilePurchase['product_id'] = this.profileInvestmentNavDataCINV['product_id'];
    this.profilePurchase['holder'] = this.profileInvestmentNavDataCINV['holder'];
    this.profilePurchase['flujo'] = this.profileInvestmentNavDataCINV['flujo'];
    this.profilePurchase['ecommerce']['transaction_id'] =`${caso==="undefined"?sessionStorage.getItem('casoSeleccionado'):caso}`;
    this.profilePurchase['ecommerce']['items'][0]['item_name'] = this.profileInvestmentNavDataCINV['product'];
    let itemIdType = (this.profilePurchase['type']) ? this.profilePurchase['type'] : 'generico';
    this.profilePurchase['ecommerce']['items'][0]['item_id'] = this.profileInvestmentNavDataCINV['product'] + '_' + itemIdType;

    //Objeto creado para enviar dataLayer con valores no undefined, null o ''  //MEIF0-7226
    try {

      const profilePurchase = {
        'event': 'purchase',
        'process': 'Contratación eCommerce',
        'category': 'inversion',
        'content_type': 'proceso_contratacion',
        'clientType': 'Cliente Tipo B',
        'ecommerce': {
          'currency': 'EUR',
          'value': 40,

        }
      }

      if (this.profilePurchase[ 'product' ]) { profilePurchase[ 'product' ] = this.profilePurchase[ 'product' ] }
      if (this.profilePurchase[ 'clientId' ]) { profilePurchase[ 'clientId' ] = this.profilePurchase[ 'clientId' ] }
      if (this.profilePurchase[ 'step' ]) { profilePurchase[ 'step' ] = this.profilePurchase[ 'step' ] }
      if (this.profilePurchase[ 'product_id' ]) { profilePurchase[ 'product_id' ] = this.profilePurchase[ 'product_id' ] }
      if (this.profilePurchase[ 'flujo' ]) { profilePurchase[ 'flujo' ] = this.profilePurchase[ 'flujo' ] }
      if (this.profilePurchase[ 'type' ]) { profilePurchase[ 'type' ] = this.profilePurchase[ 'type' ] }
      if (this.profilePurchase[ 'origen' ]) { profilePurchase[ 'origen' ] = this.profilePurchase[ 'origen' ] }
      if (this.profilePurchase[ 'seccion' ]) { profilePurchase[ 'seccion' ] = this.profilePurchase[ 'seccion' ] }
      if (this.profilePurchase[ 'holder' ]) { profilePurchase[ 'holder' ] = this.profilePurchase[ 'holder' ] }
      if (this.profilePurchase[ 'clientInfo' ]) { profilePurchase[ 'clientInfo' ] = this.profilePurchase[ 'clientInfo' ] }
      if (this.profilePurchase[ 'oferta' ]) { profilePurchase[ 'oferta' ] = this.profilePurchase[ 'oferta' ] }
      if (this.profilePurchase[ 'ecommerce' ][ 'transaction_id' ]) { profilePurchase[ 'ecommerce' ][ 'transaction_id' ] = this.profilePurchase[ 'ecommerce' ][ 'transaction_id' ] }
      if (this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ]) {
        profilePurchase[ 'ecommerce' ] = {
          'items': [
            {
              'item_name': this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ],
              'item_id': this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]
            }
          ]
        } = this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ] };
      if (this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]) {
        profilePurchase[ 'ecommerce' ] = {
          'items': [
            {
              'item_id': this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]
            }
          ]
        } = this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]
      };

      this.pushNavData({ ...profilePurchase });
    } catch (error) {
      this.pushNavData(this.profilePurchase);
    }
  }

  public triggerPurchaseFINT() {
    const caso = sessionStorage.getItem('numCaso') || sessionStorage.getItem('casoSeleccionado');
    this.profilePurchase['origen'] = this.profileInvestmentNavDataFINT['origen'];
    this.profilePurchase['seccion'] = this.profileInvestmentNavDataFINT['seccion'];
    this.profilePurchase['oferta'] = this.profileInvestmentNavDataFINT['oferta'];
    this.profilePurchase['type'] = this.profileInvestmentNavDataFINT['type'];
    this.profilePurchase['clientId'] = this.profileInvestmentNavDataFINT['clientId'];
    this.profilePurchase['step'] = this.profileInvestmentNavDataFINT['step'];
    this.profilePurchase['product'] = this.profileInvestmentNavDataFINT['product'];
    this.profilePurchase['product_id'] = this.profileInvestmentNavDataFINT['product_id'];
    this.profilePurchase['holder'] = this.profileInvestmentNavDataFINT['holder'];
    this.profilePurchase['ecommerce']['transaction_id'] =`${caso==="undefined"?sessionStorage.getItem('casoSeleccionado'):caso}`;
    this.profilePurchase['ecommerce']['items'][0]['item_name'] = this.profileInvestmentNavDataFINT['product'];
    let itemIdType = (this.profilePurchase['type']) ? this.profilePurchase['type'] : 'generico';
    this.profilePurchase['ecommerce']['items'][0]['item_id'] = this.profileInvestmentNavDataFINT['product'] + '_' + itemIdType;

    //Objeto creado controlar datos/campos undefined, null, o ' ' // MEIF0-7227
    try {

      const profilePurchase = {
        'event': 'purchase',
        'process': 'Contratación eCommerce',
        'category': 'inversion',
        'content_type': 'proceso_contratacion',
        'clientType': 'Cliente Tipo B',
        'ecommerce': {
          'currency': 'EUR',
          'value': 40,
          'item_name': '',
          'item_id': ''
        }
    };

      if (this.profilePurchase[ 'product' ]) { profilePurchase[ 'product' ] = this.profilePurchase[ 'product' ] }
      if (this.profilePurchase[ 'clientId' ]) { profilePurchase[ 'clientId' ] = this.profilePurchase[ 'clientId' ] }
      if (this.profilePurchase[ 'step' ]) { profilePurchase[ 'step' ] = this.profilePurchase[ 'step' ] }
      if (this.profilePurchase[ 'product_id' ]) { profilePurchase[ 'product_id' ] = this.profilePurchase[ 'product_id' ] }
      if (this.profilePurchase[ 'flujo' ]) { profilePurchase[ 'flujo' ] = this.profilePurchase[ 'flujo' ] }
      if (this.profilePurchase[ 'type' ]) { profilePurchase[ 'type' ] = this.profilePurchase[ 'type' ] }
      if (this.profilePurchase[ 'origen' ]) { profilePurchase[ 'origen' ] = this.profilePurchase[ 'origen' ] }
      if (this.profilePurchase[ 'seccion' ]) { profilePurchase[ 'seccion' ] = this.profilePurchase[ 'seccion' ] }
      if (this.profilePurchase[ 'holder' ]) { profilePurchase[ 'holder' ] = this.profilePurchase[ 'holder' ] }
      if (this.profilePurchase[ 'clientInfo' ]) { profilePurchase[ 'clientInfo' ] = this.profilePurchase[ 'clientInfo' ] }
      if (this.profilePurchase[ 'oferta' ]) { profilePurchase[ 'oferta' ] = this.profilePurchase[ 'oferta' ] }
      if (this.profilePurchase[ 'ecommerce' ][ 'transaction_id' ]) { profilePurchase[ 'ecommerce' ][ 'transaction_id' ] = this.profilePurchase[ 'ecommerce' ][ 'transaction_id' ] }
      if (this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ]) {
        profilePurchase[ 'ecommerce' ] = {
          'items': [
            {
              'item_name': this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ],
              'item_id': this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]
            }
          ]
        } = this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_name' ] }

      if (this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]) {
        profilePurchase[ 'ecommerce' ] = {
          'items': [
            {
              'item_id': this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ]
            }
          ]
        } = this.profilePurchase[ 'ecommerce' ][ 'items' ][ 0 ][ 'item_id' ] }
    
      this.pushNavData({ ...profilePurchase });
    } catch (error) {
      this.pushNavData(this.profilePurchase);
    }
  }

  public triggerCTADataC2C() {
    let formDescription = 'c2c_' + this.currentPageName;
    const formattedData = { eventAction: formDescription, eventLabel: 'Formulario enviado c2c' };

    this.pushInteractionData({
      ...this.defaultInteractionData,
      ...formattedData
    });
  }

  public triggerC2C(isCinv: boolean) {
    this.profileC2C['origen'] = this.profileInvestmentNavDataCINV['origen'] || this.profileInvestmentNavDataFINT['origen'];
    this.profileC2C['seccion'] = this.profileInvestmentNavDataCINV['seccion'] || this.profileInvestmentNavDataFINT['seccion'];
    this.profileC2C['oferta'] = this.profileInvestmentNavDataCINV['oferta'] || this.profileInvestmentNavDataFINT['oferta'];
    this.profileC2C['type'] = this.profileInvestmentNavDataCINV['type'] || this.profileInvestmentNavDataFINT['type'];
    this.profileC2C['clientId'] = this.profileInvestmentNavDataCINV['clientId'] || this.profileInvestmentNavDataFINT['clientId'];
    this.profileC2C['step'] = this.profileInvestmentNavDataCINV['step'] || this.profileInvestmentNavDataFINT['step'];
    this.profileC2C['product'] = this.profileInvestmentNavDataCINV['product'] || this.profileInvestmentNavDataFINT['product'];
    this.profileC2C['product_id'] = this.profileInvestmentNavDataCINV['product_id'] || this.profileInvestmentNavDataFINT['product_id'];
    this.profileC2C['holder'] = this.profileInvestmentNavDataCINV['holder'] || this.profileInvestmentNavDataFINT['holder'];
    if(isCinv){
      this.profileC2C['flujo'] = this.profileInvestmentNavDataCINV['flujo'];
    }else {
      this.profileC2C['eventCategory'] = 'Contratación Fondo Inteligente';
      this.profileC2C['eventAction'] = 'C2C_Fondo Inteligente';
    }
    //Objeto creado controlar datos/campos undefined, null, o ' ' // MEIF0-7227
    try {

      const profileC2C = {
        'event': 'c2c',
        'process': 'Contratación eCommerce',
        'category': 'inversion',
        'content_type': 'formulario_enviado_c2c',
        'clientType': 'Cliente Tipo B',
        'eventCategory': 'Contratación Cuenta Inversora',
        'eventAction': 'C2C_Cuenta Inversora',
        'eventLabel': 'Formulario enviado C2C',
        'currency': 'EUR',
        'value': 5,
      }
      if (this.profileC2C['product']) { profileC2C['product'] = this.profileC2C['product'] }
      if (this.profileC2C['clientId']) { profileC2C['clientId'] = this.profileC2C['clientId'] }
      if (this.profileC2C['step']) { profileC2C['step'] = this.profileC2C['step'] }
      if (this.profileC2C['product_id']) { profileC2C['product_id'] = this.profileC2C['product_id'] }
      if (this.profileC2C['type']) { profileC2C['type'] = this.profileC2C['type'] }
      if (this.profileC2C['origen']) { profileC2C['origen'] = this.profileC2C['origen'] }
      if (this.profileC2C['seccion']) { profileC2C['seccion'] = this.profileC2C['seccion'] }
      if (this.profileC2C['holder']) { profileC2C['holder'] = this.profileC2C['holder'] }
      if (this.profileC2C['clientInfo']) { profileC2C['clientInfo'] = this.profileC2C['clientInfo'] }
      if (this.profileC2C['oferta']) { profileC2C['oferta'] = this.profileC2C['oferta'] }

      this.pushNavData({...profileC2C});
    }   catch(error) {
      this.pushNavData(this.profileC2C);
    }
  }

}
