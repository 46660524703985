import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Otp } from 'app/collections/otp';
import { ValidateOtp } from 'app/collections/validate.otp';
import { OtpService } from 'app/services/otp.service';
import { ApiOtpService } from 'app/services/api/api.otp.service';
import { ModalService } from 'app/services/modal.service';
import { OtpDetailsProfileComponent} from 'app/modules/profile/otp-details-profile/otp-details-profile.component';
import { PersonaService } from '../../../services/persona.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from 'app/services/helpers.service';
import { DeviceService } from 'app/services/device.service';
import { AmpliacionService } from '../../../services/ampliacion.service';
import { environment } from 'environments/environment';
import { ErrorService } from '../../../services/error.service';


@Component({
  selector: 'confirm-otp-profile',
  templateUrl: './confirm-otp-profile.component.html',
  styleUrls: ['./confirm-otp-profile.component.scss'],
  providers: [ModalService]
})
export class ConfirmOtpProfileComponent implements OnInit, AfterViewInit{

  public paramsSMS: Otp;
  loading: boolean = false;

  /**Loading custom */
  public titulo = "";
  public imagen = "";
  public subtitulo = "";
  public boton = "";
  public loadCustom: boolean = this.loading; //Tipo boolean

  public nameError: string = '';
  public showError: boolean = false;

  private operacionAmpliacion: string = '';

  @ViewChild(OtpDetailsProfileComponent) otpDetails: OtpDetailsProfileComponent;

  constructor(private authService: AuthService,
              public modService: ModalService,
              private helpers: HelperService,
              private deviceService: DeviceService,
              private otpService: OtpService,
              private personaSrv: PersonaService,
              private analytics: AnalyticsService,
              private ampliacionService: AmpliacionService,
              private errService:ErrorService) { }

  ngOnInit() {
    const persona = JSON.parse(sessionStorage.getItem("userInfo"));
    this.errService.modDataError = true;
    this.paramsSMS = new Otp({
      product : "CuentaInteligente",
      userId: persona.datosPersonales.id_cliente_EVO
    });
    if(sessionStorage.getItem('operacion-ampliacion')){
      if (parseInt(sessionStorage.getItem('limite-solicitado')) >= 3000) {
        this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Paso 02.3 - OTP', 'creditLimit': 'Mayor de 3K€'});
      }
      else{
        this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Paso 02.3 - OTP', 'creditLimit': 'Menor de 3K€'});
      }
    }

  }

  ngAfterViewInit() {
    this.sendSmsOTP();
  }

  sendSmsOTP (phone: string = null) {
    const params = this.paramsSMS;
    let otpPhone = phone ? phone : JSON.parse(sessionStorage.getItem("userInfo")).datosPersonales.telefonoMovil;

    if (typeof otpPhone === 'undefined') {
        this.otpDetails.loading = false;
        this.otpDetails.modal.withImage = false;
        this.otpDetails.modal.title = '¡Ups!';
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpService.openModal(false);
        return;
    }

    this.otpService.generateSecurizedOtp(params).subscribe(data => {
      if (data.operationErrorMessage) {
        this.otpDetails.modal.title = '¡Ups!';
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpDetails.loading = false;
        this.otpService.openModal(false);
        this.triggerFormData('Datos Confirmación otp', `Envio KO${data.operationErrorMessage}`);
        return
      }
      this.otpDetails.otpSentCount = 0;
      this.otpDetails.otpId = data.idOperacion;
      this.otpDetails.loading = false;
    }, _error => {
      this.triggerFormData('Datos Confirmación otp', `Envio KO: Servicio caído`);
      this.otpDetails.loading = false;
    });
  }

  onResendSMS(event) {
    this.sendSmsOTP();
  }


  onSendSMS(event) {
    this.otpDetails.analytics.triggerInteractionData('Enviar SMS');
    this.otpDetails.loading = true;
    if (this.otpDetails.temporalMobilePhone !== this.paramsSMS.telephone){
      this.authService.updateProspect(this.getNewMobilePhone()).subscribe(data => {
        this.otpDetails.loading = false;
        if (data.response.codigo !== 'OK') {
          const modalToShow = this.modService.selectStatusModal(data.response);
          this.otpDetails.modal = modalToShow;
          this.triggerFormData('Datos Confirmación otp', `Envio KO: ${this.otpDetails.modal.message}`);
          this.otpService.openModal(true);
        }else {
          this.sendSmsOTP(this.otpDetails.temporalMobilePhone);
        }
      }, error => {
        if (error.status == 401)
          this.errService.navToError();
        this.triggerFormData('Datos Confirmación otp', `Envio KO: Fallo en Sms`);
        this.otpDetails.modal.title = '¡Ups!';
        this.otpDetails.modal.withImage = false;
        this.otpDetails.modal.blocking = false;
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpService.openModal(false);
        this.otpDetails.loading = false;
      });
    }else{
      this.sendSmsOTP(this.otpDetails.temporalMobilePhone);
    }
  }


  onSendCode(event) {
    const params:ValidateOtp = event;

    if (typeof params.code === 'undefined') {
      this.otpDetails.modal.title = '¡Ups!';
      this.otpDetails.modal.blocking = false;
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
      this.otpService.openModal(false);
      return;
    }
    this.otpService.validateOTP(params).subscribe(data => {

      if (!data.validated) {
        this.otpDetails.otpSentCount ++;
        this.otpDetails.modal.title = '¡Ups!';
        this.triggerFormData('Datos Confirmación otp', `Envio KO: ${data['operationErrorMessage']}`);
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpDetails.modal.withImage = false;
        this.otpService.openModal(false);

      } else {
        this.authService.validatedOtp = data.validated;
        this.triggerFormData('Datos Confirmación otp', `Envio OK`);
        this.actualizarDatos();

      }
    }, error => {
      if (error.status == 401)
        this.errService.navToError();

      this.triggerFormData('Datos Confirmación otp', `Envio KO: 500 Server error`);
      this.otpDetails.modal.title = '¡Ups!';
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.blocking = false;
      this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
      this.otpService.openModal(false);
    });

  }

  getNewMobilePhone() {
    const canal = this.deviceService.getDevice();
    return {
      canal: canal,
      codigoEntidad: '0239',
      datosPersonales: {
        oficinaCliente: environment.oficina,
        telefonoMovil: this.otpDetails.temporalMobilePhone
      }
    };
  }

  actualizarDatos() {

    const datos = JSON.parse(sessionStorage.getItem("datos-modificar"));
    const EE_I_Modificacion = Object.assign(datos);
    const datosJson = Object.assign(EE_I_Modificacion);
    this.showError = true;
    this.nameError = "loadingCustom";

    if(sessionStorage.getItem('operacion-ampliacion')){
      this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Paso 02.4-Validando datos' });
    }
    else {
      this.analytics.triggerProfileModDataNavData({ 'pageName': 'Perfil/Validando datos' });
    }

    this.personaSrv.sendModifyData(EE_I_Modificacion).subscribe((data) => {

      //Return early

      if (data.codigo === "KO") {
        sessionStorage.setItem('peticion-modificacion', 'false');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'mi-perfil');
        sessionStorage.setItem('showTodoOK', 'false');
        return;
      }

      //Datos modificados correctamente
      if(data.codigo !== "KO"){
        sessionStorage.removeItem("notificacionOk");
        if(!sessionStorage.getItem('operacion-ampliacion')){
          //this.analytics.triggerProfileModDataNavData({ 'pageName': 'Perfil/Modificación de datos OK' });
        }
        sessionStorage.setItem('showTodoOK', 'true');
      }

      if (sessionStorage.getItem('updateData') === "modDatos") {
        this.helpers.navigateTo('/subir-documentacion');
        return;
      }

      const operacionesAmpliacion = ["ampliacion", "ampliacion-firma", "modificar"];
      let operacionAmpliacion = sessionStorage.getItem('operacion-ampliacion');
      let isOpeacionAmpliacion = operacionesAmpliacion.indexOf(operacionAmpliacion)!== -1;

      if (isOpeacionAmpliacion) {
        this.ampliacionService.putLimite("aportar-documentacion", "aportar-documentacion");
        sessionStorage.setItem('peticion-modificacion', 'true');
        return;
      }

      //console.log("Hemos modificado tus datos correctamente");
      sessionStorage.setItem('peticion-modificacion', 'true');
      this.helpers.navigateTo('/mi-perfil');

    }, _error => {
      this.triggerFormData('Modificacion Datos', 'Envio KO: 500 Server error');
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });

  }

  triggerFormData(form, submitStatus){
    if (sessionStorage.getItem('operacion-ampliacion')){
      this.analytics.triggerExtendLimitFormData(submitStatus, sessionStorage.getItem('limite-solicitado'));
    }
    else {
    this.analytics.triggerFormData(form, submitStatus);
    }

  }

}
