import { MonitoringConfig } from "@evobanco/arq-otel-ng-monitoring-legacy";

export const monitoringConfig: MonitoringConfig = {
  serviceName: "<@__monitoringConfig_serviceName__@>",
  metricsUrl: "<@__monitoringConfig_metricsUrl__@>",
  spansUrl: "<@__monitoringConfig_spansUrl__@>",
  logsUrl: "<@__monitoringConfig_logsUrl__@>",
  ignoredPaths: ["?format=json", "assets/palabrasMalsonantes.json"],
};

const keycloakConfig = {
  url: "<@__keycloakOptions_config_url__@>",
  realm: "<@__keycloakOptions_config_realm__@>",
  clientId: "<@__keycloakOptions_config_clientId__@>",
};

const keycloakInitOptions = {
  // onLoad: 'login-required',
  checkLoginIframe: false,
};

const keycloakOptions = {
  config: keycloakConfig,
  initOptions: keycloakInitOptions,
  enableBearerInterceptor: false,
  loadUserProfileAtStartUp: false,
  bearerExcludedUrls: ["/assets"],
};

const obj = {
  production: '<@__production__@>',
  apiUrl: `<@__apiUrl__@>`,
  urlLogalty: '<@__urlLogalty__@>',
  videoId: '<@__videoId__@>',
  videoUrl: '<@__videoUrl__@>',
  urlAdrum: '<@__urlAdrum__@>',
  appKey: '<@__appKey__@>',
  oficina: '<@__oficina__@>',
  urlCookies: 'https://onetrustevo.azureedge.net/onetrust/v1/evobanco/com/oneTrust_production/consent/d09ff370-4049-4641-8671-4943228a0dcb/otSDKStub.js',
  type: '<@__type__@>',
  appRoutes: [
    {
      path: "",
      loadChildren: "<@__appRoutes_0_loadChildren__@>",
    },
    {
      path: "",
      loadChildren: "<@__appRoutes_1_loadChildren__@>",
    },
    {
      path: "",
      loadChildren: "<@__appRoutes_2_loadChildren__@>",
    },
    { path: "", redirectTo: "/", pathMatch: "full" },
    { path: "**", redirectTo: "/404", pathMatch: "full" },
  ],

  version: "",
  evoprotectUrl: "<@__evoprotectUrl__@>",
  otpClientId: "<@__otpClientId__@>",
  otpClientSecret: "<@__otpClientSecret__@>",
  ssoURL: "<@__ssoURL__@>",
  endpoint: location.origin + location.pathname,
  consoleUrl: "<@__consoleUrl__@>",
  cuentaInversoraUrl: "<@__cuentaInversoraUrl__@>",
  keycloakOptions: keycloakOptions,
  inversisUrl: "<@__inversisUrl__@>",
  urlGlance: "<@__urlGlance__@>",
  urlBackFragment: "<@__urlBackFragment__@>",
  monitoringConfig,
};

const date = new Date();
const version = `${date} v${obj.type}`;
obj.version = version;

export const environment = obj;

export const environmentPro = obj;
