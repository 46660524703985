import { Injectable } from '@angular/core';
import { Otp } from '../collections/otp';
import { ValidateOtp } from '../collections/validate.otp';
import { ApiOtpService } from './api/api.otp.service';
import { Observable, Subject} from 'rxjs';
import { ValidateSecurizedOtp, ValidateSecurizedOtpResponse } from 'app/collections/validate.securized.otp';
import { OtpTypeA } from 'app/collections/otp-type-a';

@Injectable()
export class OtpService {

  subjectModal = new Subject<any>();
  obsModal = this.subjectModal.asObservable();

  constructor(private api: ApiOtpService) {}


  //POST /generateOTP
  generateOTP(telephone: string): Observable<Otp> {
    return this.api.generateOtp(telephone);
  }

  //POST /generateSecurizedOtp
  generateSecurizedOtp(otp: Otp): Observable<Otp> {
    return this.api.generateSecurizedOtp(otp);
  }

  //POST /generateSecurizedOtp
  generateSecurizedOtpTypeA(otp: OtpTypeA): Observable<Otp> {
    return this.api.generateSecurizedOtpTypeA(otp);
  }

  //POST /validateOTP
  validateOTP(otp: ValidateOtp): Observable<ValidateOtp> {
    return this.api.validateOTP(otp);
  }

  //POST /validateOTP
  validateSecurizedOtp(otp: ValidateSecurizedOtp): Observable<ValidateSecurizedOtpResponse> {
    return this.api.validateSecurizedOtp(otp);
  }

  openModal(blocking){
    this.subjectModal.next(blocking);
  }

}
