import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Persona } from '../../collections/persona';
import { ResponseSecurity } from '../../collections/security';
import { Observable} from 'rxjs';
import { User } from '../../collections/user';
import { HelperService } from 'app/services/helpers.service';
import { map } from 'rxjs/operators';
import { InversisInvestmentsResponse, InversisSessionRequest, InversisSessionResponse } from 'app/collections/inversis';
import { CInvOrquestadorResponse } from 'app/collections/CInvOrquestadorResponse';
import { SessionStorageService } from '../session-storage.service';

const API_URL = environment.apiUrl;
const CLIENT_ID = environment.production ? '' : '';
const CLIENT_SECRET = environment.production ? '' : '';
console.log(environment);
@Injectable()
export class ApiPersonService {

  private refresh: object = {};
  private options = {};
  public idCli;
  public idInternoPe;

  constructor(
    private httpClient: HttpClient,
    private helperSrv: HelperService,
    public sessionStorageSrv: SessionStorageService
  ) {
    this.options = { withCredentials: true, observe: 'response' };
  }

  public getAllInfo(): Observable<Persona[]> {
    return this.httpClient
      .get(API_URL + '/fullonline/salesforce/personas')
      .pipe(map(response => response['body']));
      // .pipe(map(response => {
      //   const persona = response['body'];
      //   persona.map((persona) => new Persona(persona));
      //   new Persona(response['body'])
      // }));
  }

  private serialize(obj, prefix) {
    var str = [], p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  // API: POST /personas
  public createPersona(persona: Persona, product?) {
    let clonePersona:Persona = this.helperSrv.cloneAndConvertToUpperCase(persona);
    if(product){
      clonePersona.logalty = Object.assign({ codProductoContratado: product });
    }
    return this.httpClient
      .post(API_URL + '/fullonline/salesforce/personainfo/v1/personas', clonePersona, this.options)
      .pipe(map(response => response['body']));
  }

  // API: GET /persona/:id
  public getPersonaById(personaId: string, codProducto?) {
    let producto = '';
    if(typeof codProducto === 'object'){
      codProducto = codProducto.codProductoContratado;
    }
    if (codProducto) producto = '?codProductoContratado='+codProducto;
    return this.httpClient
      .get(API_URL + '/evobanco/onboarding/multi-ci/v1/cuenta-inteligente/personas/' + personaId + producto, this.options)
      .pipe(
        map(res => {
          return new Persona(res['body']);
        })
      )
  }

  // API: PUT /personas/:id
  public updatePersona(persona: Persona,product?) {
    let clonePersona = this.helperSrv.cloneAndConvertToUpperCase(persona);
    if (product)clonePersona.logalty = Object.assign({ codProductoContratado: product });
    let prospect = JSON.parse(sessionStorage.getItem('currentProspect'));
    let isRegistro = !prospect && sessionStorage.getItem('persona') && JSON.parse(sessionStorage.getItem('persona')).estado === "registro";

    if(prospect && !isRegistro){
      prospect = prospect.datosPersonales.id_cliente_EVO;
      return this.httpClient
        .put(API_URL + '/evobanco/onboarding/multi-ci/v1/cuenta-inteligente/personas/' + prospect, clonePersona, this.options)
        .pipe(
          map(res => {
            return new Persona(res['body']);
          })
        )
    }
  }

  public updatePersonConfirma(persona: Persona, product?) {
    let clonePersona = this.helperSrv.cloneAndConvertToUpperCase(persona);
    if (product) clonePersona.logalty = Object.assign({ codProductoContratado: product });
    let prospect = JSON.parse(sessionStorage.getItem('currentProspect'));
    if(prospect) prospect = prospect.datosPersonales.id_cliente_EVO;
    else prospect = 'cGVyc29uYQ==';
    return this.httpClient
      .put(API_URL + '/fullonline/salesforce/personainfo/v1/datosConfirma/' + prospect, clonePersona, this.options)
      .pipe(map(response => response['body']));
  }

  // DELETE /personas/:id
  public deletePersonaById(personaId: string): Observable<null> {
    personaId.toUpperCase();
    return this.httpClient
      .delete(API_URL + '/fullonline/salesforce/personainfo/v1/personas/' + personaId)
      .pipe(map(res => null));
  }

  // API: GET /persona/:id
  public checkDuplicateDNI(rsi_data: Object): Observable<Response> {
    let cloneRSIData = this.helperSrv.cloneAndConvertToUpperCase(rsi_data);
    return this.httpClient
    .post(API_URL + '/evobanco/onboarding/multi-ci/v1/fullonline/persona/dni', cloneRSIData, this.options)
    .pipe(
      map(res => {
        return res['body'];
      }));
  }

  public checkPhoneEmail(data: Object): Observable<Response> {
    let cloneData = this.helperSrv.cloneAndConvertToUpperCase(data);
    return this.httpClient
      .post(API_URL + '/fullonline/rsi_api/clientes/v1.0.0', cloneData, this.options)
      .pipe(map(res => {
        return res['body'];
      }));
  }

  // TODO: Delete (deprecated after Consola 2.0)
  public getWarnings(idCliente: string) {
    let prospect = JSON.parse(sessionStorage.getItem('userData'));
    if (prospect && prospect.persona.id_cliente_EVO){
      return this.httpClient
        .get(`${API_URL}/fullonline/salesforce/comprobacionCliente/v1/${prospect.persona.id_cliente_EVO}`, this.options)
        .pipe(map(res => {
          return res['body'];
        }));
    }
  }

  public getInfoSolicitud(idCliente: string) {
    return this.httpClient
      .get(API_URL + '/fullonline/salesforce/infoSolicitud/v1/' + idCliente, this.options)
      .pipe(map(res => {
        return res['body'];
      }));
  }

  public sendDni(persona: Persona): Observable<any> {
    let prospect = JSON.parse(sessionStorage.getItem('userData'));
    if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
        .post(`${API_URL}/fullonline/integracion/confirma/sendGenerateFull/${prospect.persona.id_cliente_EVO}`, persona, this.options)
        .pipe(map(response => response['body']));
  }

  public sendDniAmpliacion(persona: Persona): Observable<any> {
    let prospect = JSON.parse(sessionStorage.getItem('userData'));
    if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
        .post(`${API_URL}/fullonline/integracion/subidaDocumentacion/v1/datosConfirma/${prospect.persona.id_cliente_EVO}`, persona, this.options)
        .pipe(map(response => response['body']));
  }

  public sendModifyData(data): Observable<any> {
    let prospect = JSON.parse(sessionStorage.getItem('userData'));
    if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
        .post(`${API_URL}/fullonline/salesforce/personainfo/v1/modificaPersona/${prospect.persona.id_cliente_EVO}`, data, this.options)
        .pipe(map(response => response['body']));
  }


  public consultaExperian(idCliente: string, producto) {
    let prod = Object.assign({ codProducto: producto });
    return this.httpClient
      .post(API_URL + '/fullonline/salesforce/consultaExperianPost/v1/' + idCliente, prod, this.options)
      .pipe(map(res => {
         return res['body'];
    }));
    }

    public consultaAsnef(idCliente: string, producto) {
    let prod = Object.assign({ codProducto: producto });
    return this.httpClient
     .post(API_URL + '/fullonline/salesforce/consultaAsnefPost/v1/' + idCliente, prod, this.options)
     .pipe(map(res => {
       return res['body'];
    }));
    }

    public consultaExperianAmpliacion(idCliente: string, producto) {
      let prod = producto;
      return this.httpClient
        .post(API_URL + '/fullonline/salesforce/consultaExperianPost/v1/' + idCliente, prod, this.options)
        .pipe(map(res => {
           return res['body'];
      }));
      }

      public consultaAsnefAmpliacion(idCliente: string, producto) {
      let prod = producto;
      return this.httpClient
       .post(API_URL + '/fullonline/salesforce/consultaAsnefPost/v1/' + idCliente, prod, this.options)
       .pipe(map(res => {
         return res['body'];
      }));
      }

    public crearCasoLimite(data) { // ID CLIENTE SF
      let prospect = JSON.parse(sessionStorage.getItem('userData'));
    if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
       .post(`${API_URL}/fullonline/salesforce/ampliacionLimitePost/v1/${prospect.persona.id_cliente_EVO}`, data, this.options)
       .pipe(map(res => {
         return res['body'];
    }));
    }

    public disminuirLimite(data) { // ID CLIENTE SF
      let prospect = JSON.parse(sessionStorage.getItem('userData'));
      if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
      .post(API_URL + `/fullonline/salesforce/disminucionLimitePost/v1/${prospect.persona.id_cliente_EVO}`, data, this.options)
       .pipe(map(res => {
         return res['body'];
    }));
    }

    public modificarLimite(data) { // ID CLIENTE SF
      let prospect = JSON.parse(sessionStorage.getItem('userData'));
      if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
       .put(`${API_URL}/fullonline/salesforce/ampliacionLimitePut/v1/${prospect.persona.id_cliente_EVO}`, data, this.options)
       .pipe(map(res => {
         return res['body'];
    }));
    }

    public consultarCasoAmpliacion(idCliente: string, idCaso: string) {
      let prospect = JSON.parse(sessionStorage.getItem('userData'));
      if (prospect && prospect.persona.id_cliente_EVO){
        this.idCli = prospect.persona.id_cliente_EVO;
      }
      else {
        this.idCli = idCliente;
      }
      return this.httpClient
       .get(`${API_URL}/fullonline/salesforce/ampliacionLimiteGet/v1/${this.idCli}?idCaso=${idCaso}`, this.options)
       .pipe(map(res => {
         return res['body'];
    }));
    }

    public cancelarAmpliacion(data) {
      let prospect = JSON.parse(sessionStorage.getItem('userData'));
      if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
       .put(`${API_URL}/fullonline/salesforce/cancelaAmpliacionPut/v1/${prospect.persona.id_cliente_EVO}`, data, this.options)
       .pipe(map(res => {
         return res['body'];
    }));
    }

    public consultarCasosCliente() { // ID CLIENTE EVO
      let prospect = JSON.parse(sessionStorage.getItem('userData'));
      if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
       .get(`${API_URL}/fullonline/salesforce/consultaCasosCliente/v1/${prospect.persona.id_cliente_EVO}`, this.options)
       .pipe(map(res => {
         return res['body'];
    }));
    }

    public subirDocumentacionAmpliacion(data) {
      let prospect = JSON.parse(sessionStorage.getItem('userData'));
      if (prospect && prospect.persona.id_cliente_EVO)
      return this.httpClient
       .post(`${API_URL}/fullonline/salesforce/documentacionAmpliacion/v1/${prospect.persona.id_cliente_EVO}`, data, this.options)
       .pipe(map(res => {
         return res['body'];
    }));
    }

    /******** LLamadas a la API de Cuenta Inversora *********/

  public crearCasoCuenta(data): Observable<any> {
    let idClienteEvo = sessionStorage.getItem('idClienteEvo');
    return this.httpClient
      .post(`${API_URL}/fullonline/salesforce/cuentaInversoraPost/${idClienteEvo}`, data, this.options)
      .pipe(map(res => {
        return res['body'];
      }));
  }

  public modificarCuenta(data): Observable<any> {
    let idClienteEvo = sessionStorage.getItem('idClienteEvo');
    return this.httpClient
      .put(`${API_URL}/fullonline/salesforce/cuentaInversoraPut/v1/${idClienteEvo}`, data, this.options)
      .pipe(map(res => {
        return res['body'];
      }));
  }

  public consultarDatosCasoInversora(idCliente: string, idCaso: string): Observable<any> {
    return this.httpClient
      .get(`${API_URL}/fullonline/salesforce/cuentaInversoraGet/${idCliente}?idCaso=${idCaso}`, this.options)
      .pipe(map(res => {
        return res['body'];
      }));
  }

  public consultarCasosClienteInversora(): Observable<any> {
    let idClienteEvo = sessionStorage.getItem('idClienteEvo');
    return this.httpClient
      .get(`${API_URL}/fullonline/salesforce/consultaCasosCliente/v1/${idClienteEvo}`, this.options)
      .pipe(map(res => {
        return res['body'];
      }));
  }

    /**Probar que funciona bien */
    public consultaLogin(user): Observable<User> {
      return this.httpClient
      .post(`${API_URL}/fullonline/salesforce/personainfo/v1/login`,
            user,
            this.options)
      .pipe(map(response =>  response['body']));
    }

    cambioEstadoLogalty(logalty): Observable<any>{
      return this.httpClient
        .post(`${API_URL}/fullonline/salesforce/personainfo/v1/logalty`,
          logalty,
          this.options
        )
        .pipe(map(response => {
          return response['body'];
        }));

        /*.catch(res => {
          if(res.status == 200) {
            return new Observable(observer => {
              observer.next({}));
              observer.complete();
            }));
          }
        });*/
    };

    getCategorizacion(): Observable<any>{

      this.idInternoPe = this.sessionStorageSrv.getItemParsed('userInfo') ? this.sessionStorageSrv.getItemParsed('userInfo').datosPersonales.idPersonaIris: sessionStorage.getItem('idInternoPe');
      return this.httpClient
        .get(`${API_URL}/fullonline/integracion/rsi/mifid?idInterno=${this.idInternoPe}`, this.options)
        .pipe(map(res => {
          return res['body'];
        }));
    }

    getNickName(nick): Observable<any>{

      return this.httpClient
        .get(`${API_URL}/fullonline/integracion/rsi/nickname?nick=${nick}`, this.options)
        .pipe(map(res => {
          return res['body'];
        }));
    }

    getPublicIP(): Observable<any>{
      return this.httpClient.get(`https://api.ipify.org/?format=json`).pipe(map(res => {
        return res['ip'];
      }));
    }

    public getInvestments(idInterno): Observable<InversisInvestmentsResponse> {
      return this.httpClient
        .get(`${API_URL}/evobanco/integrations/v1/customer/${idInterno}/investments`, this.options)
        .pipe(map(res => {
          return res['body'];
        }));
    }

    public inversisSession(data: InversisSessionRequest): Observable<InversisSessionResponse> {
      return this.httpClient
        .post(`${API_URL}/evobanco/inversis/v1/session`, data, this.options)
        .pipe(map(res => {
          return res['body'];
        }));
    }

    getStatusAndRedirect(params: any): Observable<any>{
      const orquestadorPath = `${API_URL}/evobanco/onboarding/inversora/v1`;
      return this.httpClient
        .get(`${orquestadorPath}/orquestador?${params}`, this.options)
        .pipe(
          map((response) => {
            return new CInvOrquestadorResponse(response['body']);
          })
        );
    }

}
